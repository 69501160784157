<script lang="ts" setup>
import qs from 'qs'
import { useUrlStore } from '~/store/url'

const { product } = defineProps<{
  product: any
}>()

const localePath = useLocalePath()
const urlStore = useUrlStore()
const { hasProduct } = storeToRefs(urlStore)

function getStatusSeverity(status: string) {
  switch (status) {
    case 'phaseout':
      return 'warning'
    case 'drop':
      return 'danger'
    case 'new':
      return 'success'
  }
}

function getPDFBulletin(bulletinType: BulletinTypes, product: { id: string, code: string, segment: string[], website: string, productType: string, category?: string, language: string }) {
  const data = []
  data.push({
    id: product.category === 'sheetvinyl' ? product.id.split('-vinyl-').pop() : product.code,
    segment: product.segment.filter(seg => seg !== 'all')[0].replace(/\s/g, '-'),
    website: product.website,
    productType: product.productType,
    category: product.category,
    language: product.language,
  })
  const productUrlSearchParams = qs.stringify(data, { encodeValuesOnly: true, arrayFormat: 'comma' })
  navigateTo(localePath(`${urlStore.basePdfUrl[bulletinType]}?${productUrlSearchParams}`), { open: { target: '_blank' } })
}
</script>

<template>
  <Card
    :key="`${product.name}-${product.code}-${product.segment}`"
    :class="hasProduct(product) ? 'bg-green-900' : ''"
    class="w-full lg:w-1/5"
    style="overflow: hidden"
  >
    <template #header>
      <div class="relative  h-full w-full">
        <img class=" w-full h-auto" :alt="`${product.name} ${product.code}`" :src="`https://api.beaulieucanada.com/images/${product?.raw?.thumbnail}?_w=600`">
        <Badge v-if="product.status && product.status !== 'active'" :severity="getStatusSeverity(product.status)" class="absolute right-0 mt-2 mr-2" :value="$t(`product.${product.status}`)" />
      </div>
    </template>
    <template #title>
      {{ product.name }} {{ product.code }}
    </template>
    <template #subtitle>
      <strong v-if="urlStore.selected.website === null">{{ $t(`website.${product.website}`) }}<br></strong>
      {{ $t('product.collection', product) }}
    </template>
    <template #content>
      <div class="h-7rem">
        <Chip v-for="productSegment in product.segment" :key="`${product.code}-${productSegment}`" class="mr-1 mt-1" :label="$t(`segment.${productSegment}`)" />
      </div>
    </template>
    <template #footer>
      <div class="mt-2">
        <div class="flex flex-col items-start">
          <Button class="my-1 p-0 text-left" icon="pi pi-file-pdf" icon-pos="right" :label="$t('bulletin.single')" link @click="getPDFBulletin('single', product)" />
          <Button v-if="!urlStore.exceptionProducts.technical.includes(product.category)" :disabled="!product.raw.testCategories.length" class="my-1 p-0" icon="pi pi-file-pdf" icon-pos="right" :label="$t('bulletin.technical')" link @click="getPDFBulletin('technical', product)" />
        </div>
      </div>
      <div v-if="!urlStore.exceptionProducts.technical.includes(product.category) && !urlStore.exceptionProducts.collection.includes(product.category)" class="flex justify-end mt-3">
        <Button v-show="hasProduct(product)" class="px-2 py-2" severity="danger" @click="urlStore.removeProduct(product)">
          <i class="pi pi-minus mr-1" style="font-size: 1rem;" /> {{ $t('product.remove') }}
        </Button>
        <Button v-show="!hasProduct(product)" class="px-2 py-2" severity="success" @click="urlStore.addProduct(product)">
          <i class="pi pi-plus mr-1" style="font-size: 1rem;" /> {{ $t('product.add') }}
        </Button>
      </div>
    </template>
  </Card>
</template>

<style>

</style>
